import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import BlogCard from "@/components/blog-card"
import { Link } from "gatsby"

const BlogOne = ({ pageContext }) => {

  const {pageCount, group, index, first, last} = pageContext

  const prevIndex = index - 1
  const nextIndex = index + 1
  const prevPageUrl = prevIndex === 1 ? '/blog' : `/blog/${prevIndex}`
  const nextPageUrl = nextIndex === 1 ? '/blog' : `/blog/${nextIndex}`
  
  return (
    <section className="commonSection blogPage">
      <Container>
        <Row>
          {group.map((post, index) => {
            return (
              <Col lg={4} md={6} sm={12} key={index}>
                <BlogCard post={post} />
              </Col>
            )
          })}
        </Row>
        <div className="blog-post-nav blog-list-nav">
            <div className="col-lg-12 col-sm-12 text-center w-100">
              <ul>
                <li>
                  { !first && (
                    <Link to={prevPageUrl} rel="prev">
                      ← Previous Page
                    </Link>
                  )}
                </li>
                <li>
                  { !last && (
                    <Link to={nextPageUrl} rel="next">
                      Next Page →
                    </Link>
                  )}
                </li>
              </ul>
            </div>
        </div>
      </Container>
    </section>
  )
}

export default BlogOne
