import React from "react"
import { Link } from "gatsby"

const BlogCard = ({ post }) => {
  const date = post.frontmatter.date
  const shotDesc = post.excerpt
  const title = post.frontmatter.title || post.fields.slug
  const image = post.frontmatter.image ? post.frontmatter.image.publicURL : ""
  return (
    <div className="latestBlogItem" key={post.fields.slug}>
      <div className="lbi_thumb">
        <img src={image} alt={title} />
      </div>
      <div className="lbi_details">
        {date}
        <h2>{title}</h2>
        <p>{shotDesc}</p>
        <Link className="learnM" to={post.fields.slug} itemProp="url">
          Read More <i className="fa fa-angle-right"></i>
        </Link>
      </div>
    </div>
  )
}

export default BlogCard
